const fields = {
  enumType: {
    label: '供应商类型',
    input: 'select',
    message: '请选择供应商类型',
    options: [
      {
        value: 'TRADERS',
        label: '贸易商'
      }, {
        value: 'MANUFACTURER',
        label: '生产制造商'
      }, {
        value: 'COMPLETE',
        label: '咨询服务商'
      }, {
        value: 'LOGISTICS',
        label: '物流运输商'
      }, {
        value: 'MAINTAIN',
        label: '维修供应商'
      }, {
        value: 'PROJECT',
        label: '工程建设类'
      },
    ],
    value: ''
  },
  name: {
    label: '公司名称',
    message: '请输入公司名称',
    value: '',
  },
  contacts: {
    label: '公司法人',
    message: '请输入公司法人',
    value: '',
  },
  contactPerson: {
    label: '公司业务联系人',
    message: '请输入公司业务联系人',
    value: '',
  },
  phone: {
    label: '业务联系人电话',
    message: '请输入业务联系人电话',
    value: '',
  },
  contactPerson2: {
    label: '公司主管联系人',
    message: '请输入公司主管联系人',
    value: '',
  },
  phone2: {
    label: '主管联系人电话',
    message: '请输入主管联系人电话',
    value: '',
  },
  mailbox: {
    label: '公司邮箱',
    message: '请输入公司邮箱',
    value: '',
  },
  socialCode: {
    label: '统一社会信用代码',
    message: '请输入统一社会信用代码',
    value: '',
  },
  capital: {
    label: '注册资本(万元)',
    message: '请输入注册资本',
    input: 'number',
    value: '',
  },
  address: {
    label: '公司地址',
    message: '请输入详细地址',
    value: '',
  },
  stock: {
    label: '股票代码',
    message: '请输入股票代码',
    value: '',
  },
  addvalidity: {
    label: '营业执照有效期起',
    message: '选择日期',
    value: '',
    input: 'date',
    options: {
      disabledDate(time) {
        return time.getTime() > Date.now()
      }
    }
  },
  editvalidity: {
    label: '营业执照有效期至',
    message: '永久请选择2099年后任意时间',
    value: '',
    input: 'date',
    options: {
      disabledDate(time) {
        return time.getTime() < Date.now()
      }
    }
  },
  bank: {
    label: '银行开户户头',
    message: '请输入银行开户户头',
    input: 'disable',
    value: '',
  },
  account: {
    label: '银行账号',
    message: '请输入银行账号',
    value: '',
  },
  fullAccount: {
    label: '完整开户行名称',
    message: '请输入完整开户行名称',
    value: '',
  },
  k1: {
    label: '员工总人数',
    message: '请输入员工总人数',
    input: 'number',
    value: '',
  },
  k2: {
    label: '管理人员数',
    message: '请输入管理人员数',
    input: 'number',
    value: '',
  },
  k3: {
    label: '销售人员数',
    message: '请输入销售人员数',
    input: 'number',
    value: '',
  },
  k4: {
    label: '研发人员数',
    message: '请输入研发人员数',
    input: 'number',
    value: '',
  },
  k5: {
    label: '品管人员数',
    message: '请输入品管人员数',
    input: 'number',
    value: '',
  },
  k6: {
    label: '办公/厂房总面积(m²)',
    message: '请输入办公/厂房总面积',
    input: 'number',
    value: '',
  },
  k7: {
    label: '车间数量',
    message: '请输入车间数量',
    input: 'number',
    value: '',
  },
  k8: {
    label: '车间工人总数量',
    message: '请输入车间工人总数量',
    input: 'number',
    value: '',
  },
  k9: {
    label: '设备清单',
    message: '请输入设备清单',
    input: 'textarea',
    span: 24,
    value: '',
  },
  category1: {
    label: '经营类目/大类',
    message: '所属行业',
    input: 'textarea',
    span: 8,
    value: '',
  },
  category2: {
    label: '经营类目/中类',
    message: '主要营业范围',
    input: 'textarea',
    span: 8,
    value: '',
  },
  category3: {
    label: '经营类目/小类',
    message: '与沃莱迪生产经营挂钩的产品',
    input: 'textarea',
    span: 8,
    value: '',
  },
}

//导出参数
module.exports = fields;
