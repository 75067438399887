<template>
  <div class="body-box">
    <!--    导航盒子-->
    <nav-box></nav-box>
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <span>欢迎注册!</span>
        <el-button style="margin-left: 20px;padding: 8px;border-radius: 10px;" @click="dialogVisible = true">点击关注公众号
        </el-button>
      </div>

      <div class="prompt">短信验证码验证通过以后，账号立即生成。登陆账号是登录手机号，可以从首页进行登录，也可以选择继续完善信息。有疑问请拨打: 0874-5692871</div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="提交完成"></el-step>
      </el-steps>

      <!--      注册表单区域-->
      <el-form
        ref="addFormRef"
        :model="basicsForm"
        :rules="rules"
        label-width="140px"
      >
        <el-row class="form-box">
          <!--          遍历字段-->
          <el-col :span="item.span||12" v-for="(item,key) in fields">
            <el-form-item :label="item.label" :prop="key">
              <!--              如果类型是下拉框-->
              <el-select v-if="item.input==='select'" style="width: 100%" v-model="basicsForm[key]"
                         :placeholder="item.message">
                <el-option
                  v-for="im in item.options"
                  :key="im.value"
                  :label="im.label"
                  :value="im.value">
                </el-option>
              </el-select>
              <!--              默认为输入框-->
              <el-input v-if="!item.input" v-model="basicsForm[key]"
                        :placeholder="item.message"></el-input>
              <!--              数字输入框-->
              <el-input v-if="item.input==='number'" v-model="basicsForm[key]"
                        oninput="value=value.replace(/[^\d\.]/g,'')"
                        :placeholder="item.message">
              </el-input>
              <!--              时间框-->
              <el-date-picker v-if="item.input==='date'"
                              v-model="basicsForm[key]"
                              type="date"
                              value-format="yyyy-MM-dd"
                              :placeholder="item.message"
                              :picker-options="item.options">
              </el-date-picker>
              <!--              禁用框-->
              <el-input v-if="item.input === 'disable'" disabled v-model="basicsForm[key]" :placeholder="item.message"/>
              <!--              文本域-->
              <el-input v-if="item.input === 'textarea'" v-model="basicsForm[key]" :placeholder="item.message"
                        type="textarea"/>
            </el-form-item>
          </el-col>

        </el-row>

        <!--        资质区域-->
        <div v-for="(item, index) in certifications" :key="index">
          <certification :certifications='certifications' :index="index"></certification>
        </div>

        <!--        操作区域-->
        <el-col :span="24">
          <div class="btn-box">
            <my-button @click="submitRegister">提交</my-button>
            <my-button @click="submitRegister">暂存</my-button>
          </div>
        </el-col>
      </el-form>

    </el-card>

    <qq-footer></qq-footer>

    <el-dialog
      title="关注沃莱迪公众号"
      :visible.sync="dialogVisible"
      width="30%">
      <div style="text-align: center">
        <div style="font-size: 16px;height: 50px;">扫码或搜索沃莱迪金属公众号进行关注，随时获取最新招标信息</div>
        <img src="@/assets/images/gzh.jpg" alt="">
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">已关注</el-button>
  </span>
    </el-dialog>

    <div id="second">
      <div style="text-align: center">扫码关注</div>
      <img src="@/assets/images/gzh.jpg" alt="">
    </div>


  </div>
</template>
<script>
import {rules} from '@/js/rules'
import NavBox from '@/components/common/navBox'
import certification from '@/components/common/certification'
import QqFooter from '@/components/common/qqFooter'
import {getTemplateByKey, registerSupplier} from "@/api/supplier";
import MyButton from "@/components/common/myButton";

export default {
  components: {
    MyButton,
    QqFooter,
    NavBox,
    certification
  },
  data() {
    return {
      //引入字段
      fields: require('@/js/fields'),
      //引入校验规则
      rules: rules,
      certifications: [
        {
          name: '营业执照副本',
          brief: '营业执照副本上传',
          required: ' <span style="color: red">(必填)</span>',
          md5: '',
          fileList: [],
          enumType: 'BL'
        }, {
          name: '业务委托书',
          brief: '上传业务委托书<span style="color: red">（请完善信息后，打印盖章上传）</span>',
          required: ' <span style="color: red">(必填)</span>',
          download: '',
          filename: '业务委托书.doc',
          md5: '',
          fileList: [],
          enumType: 'POA'
        }, {
          name: '质量管理体系证书',
          brief: '上传质量管理体系证书',
          required: ' <span>(选填)</span>',
          md5: '',
          fileList: [],
          enumType: 'QMSC'
        }, {
          name: '环境/安全体系/生产区域照片',
          brief: '上传环境/安全体系/生产区照片/仓储',
          required: ' <span>(选填)</span>',
          md5: '',
          fileList: [],
          enumType: 'POPA'
        }, {
          name: '特殊经营许可证',
          brief: '上传特殊经营许可证(化工/物流/医疗/检测/评估资质)',
          required: '<span>(选填)</span>',
          md5: '',
          fileList: [],
          enumType: 'SBL'
        }],
      dialogImageUrl: '',
      dialogVisible: false,
      activeIndex: 1,
      basicsForm: {
        bank: ''
      },
      distId: '',
      tempId: '',
      supplierId: '',
      formList: {}
    }
  },
  created() {
    this.getTemplateByKey()
    if (!this.$route.query.userId) {
      this.$message.error('获取信息失败,请返回上一步!')
      return
    }
    this.certifications[0].download = `${this.getaway.redirect}srm/file/e106d5e3853eb1e393fee685e602b676/download`
    this.certifications[1].download = `${this.getaway.redirect}srm/file/e106d5e3853eb1e393fee685e602b676/download`
    //查看缓存是否有
    let basicsForm = sessionStorage.getItem('basicsForm');
    if (basicsForm) {
      this.basicsForm = JSON.parse(basicsForm);
    }
    //查看缓存是否有
    let certifications = sessionStorage.getItem('certifications');
    if (certifications) {
      this.certifications = JSON.parse(certifications);
    }
  },
  mounted() {
  },
  watch: {
    //监听供应商类型
    'basicsForm.enumType'(val) {
      if (val === 'MANUFACTURER') {
        this.certifications[2].required = '<span style="color: red">(必填)</span>'
        this.certifications[3].required = '<span style="color: red">(必填)</span>'
        this.requiredField(true, 'MANUFACTURER')
      } else {
        this.certifications[2].required = '<span>(选填)</span>'
        this.certifications[3].required = '<span>(选填)</span>'
        this.requiredField(false, 'MANUFACTURER')
      }
    },
    //监听供应商名称
    'basicsForm.name'(val) {
      this.basicsForm.bank = val
    }
  },
  methods: {

    //必填字段
    requiredField(flag, type) {
      for (let r in this.rules) {
        //授权列表
        let rule = this.rules[r];
        rule.forEach(ro => {
          if (ro.requireds && ro.requireds.indexOf(type) > -1) {
            ro.required = flag
          }
        })
      }
    },
    //获取供应商key和模板
    getTemplateByKey() {
      getTemplateByKey({
        keyword: 'SUPPLIER_TEMPLATE'
      }).then(res => {
        if (res.code === '0') {
          this.tempList = res.data
          this.supplierId = res.data[0].id
        } else {
          this.$message.error("获取供应商模板失败，请刷新页面重试!")
        }
      })
    },

    // 提交表单注册信息
    submitRegister(load) {
      //默认关闭
      load.state = false;
      //先暂存刚才填写的数据，以免刷新后丢失
      sessionStorage.setItem('basicsForm', JSON.stringify(this.basicsForm));
      sessionStorage.setItem('certifications', JSON.stringify(this.certifications));
      //校验资质
      for (let i = 0; i < this.certifications.length; i++) {
        let cert = this.certifications[i];
        //所有供应必须上传业务委托书和营业执照
        if (['BL', 'POA'].indexOf(cert.enumType) > -1 && cert.md5 === '') {
          this.$message({
            message: '请上传' + cert.name + '!',
            type: 'error'
          })
          return
          //生产制造商必须上传质量管理证书、生产区域照片
        } else if (['QMSC', 'POPA'].indexOf(cert.enumType) > -1 && cert.md5 === '' && this.basicsForm.enumType === 'MANUFACTURER') {
          this.$message({
            message: '请上传' + cert.name + '!',
            type: 'error'
          })
          return
        }
      }

      //校验表单
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          if (this.basicsForm.contactPerson === this.basicsForm.contactPerson2 || this.basicsForm.phone === this.basicsForm.phone2) {
            return this.$message.error('业务联系人/业务联系电话与主管联系人/主管联系电话不能一样')
          }
          //定义资质数组
          let certs = [];
          //赋值所有资质
          this.certifications.forEach(c => {
            certs.push({
              name: c.name,
              brief: c.brief,
              md5: c.md5,
              enumType:
              c.enumType
            })
          })
          this.formList = {
            addSupplierTemplateDto: {
              json: JSON.stringify(this.basicsForm),
              srmSupplierId: this.distId,
              srmTemplateId: this.supplierId,
            },
            batchSupplierLicense: {
              licenseList: certs,
            },
            supplierDto: {
              contacts: this.basicsForm.contacts,
              address: this.basicsForm.address,
              capital: this.basicsForm.capital,
              phone: this.basicsForm.phone,
              name: this.basicsForm.name,
              mailbox: this.basicsForm.mailbox,
              enumType: this.basicsForm.enumType,
              category3: this.basicsForm.category3,
              srmUserId: this.$route.query.userId
            }
          }
          //注册供应商
          load.state = true;
          registerSupplier(this.formList).then(res => {
            load.state = false;
            if (res.code === '0' && res.data) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              sessionStorage.removeItem('basicsForm')
              sessionStorage.removeItem('certifications')
              this.$router.push('/achieve')
            }
          })
        } else {
          return this.$message.error('请完善必填资料');
        }
      })
    },

    // 点击按钮新增表单
    addFromBox() {
      const addBox = {
        hasModify: true,
        md5: [],
        fileList: []
      }
      this.certifications.push(addBox)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__footer {
  text-align: center !important;
}

#second {
  position: fixed;
  top: 40%;
  right: 4%;
  width: 100px;
  height: 130px;
  border: 1px solid silver;

  img {
    width: 100%;
    height: 100px;
  }
}

/deep/ .el-step__head.is-success {
  color: #3150E9;
}

/deep/ .el-textarea__inner {
  height: 120px;
}

/deep/ .el-step__title.is-success {
  color: #3150E9;
}

/deep/ .el-step__head.is-success {
  border-color: #3150E9;
}

.prompt {
  margin: 10px auto;
  font-size: 14px;
  color: #ec4832;
  padding: 5px;
  text-align: center;
  width: 70%;
  background-color: #f2f6f9;
}

/deep/ .select-box {
  .el-select {
    width: 100%;
  }
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 80%;
  margin: 0 auto;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.form-box {
  width: 80%;
  margin: 0 auto;
}

.note {
  width: 50%;
  margin-right: 20px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 60%;
  }

  .pic {
    position: absolute;
    top: 0;
    right: 22px;
    width: 120px;
    height: 100%;
    border: 1px solid #dcdfe6;
  }
}

.btn-box {
  display: block;
  margin: 20px auto;
  text-align: center;
}

.note-box {
  width: 40%;
  padding: 12px 0;
}

.add-box {
  width: 100%;
  height: 188px;
  line-height: 188px;
  text-align: center;
  font-size: 158px;
  color: #CCCCCC;
  margin-top: 40px;
}
</style>
